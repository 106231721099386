import configuration from './configuration.json';
import { BookData } from './interfaces/BookData';
import { AudioMetadata } from './interfaces/AudioMetadata';

export interface BookStatus {
  status: 'initializing'|'creating'|'transcribing'|'ready'|'queued';
  title?: string;
  pages?: {
    draw: 'COMPLETED' | 'PENDING' | 'STARTING';
    transcribe: 'COMPLETED' | 'PENDING' | 'STARTING';
  }[]
}

export class APIClient {
  baseUrl: string;

  constructor() {
    this.baseUrl = configuration.baseUrl;
  }

  async createBook(
    plot: string,
    characters: string | null,
    durationMinutes: number| null,
    childYearsOld: number| null,
    inspirationBook: string| null,
    inspirationAuthor: string| null,
    modelId: string,
    imageStylePreset: string,
  ): Promise<string> {
    const body = {
      plot,
      characters,
      durationMinutes,
      childYearsOld,
      inspirationBook,
      inspirationAuthor,
      modelId,
      imageStylePreset,
    };
    const response = await this.makeRequest('/new', { method: 'POST', body });
    return response.bookId;
  }

  async getBookData(bookId: string): Promise<BookData> {
    const response = await this.makeRequest(`/data/${bookId}/-1/data`);
    return response;
  }

  // async getPageAudioUrl(bookId: string, page: number): Promise<string> {
  //   const response = await this.makeRequest(`/data/${bookId}/${page.toString()}/audio`, { query: { redirect: '0' } });
  //   return response.url;
  // }

  // async getPageAudioMetadata(bookId: string, page: number): Promise<AudioMetadata> {
  //   const response = await this.makeRequest(`/data/${bookId}/${page.toString()}/audio-meta`, { query: { redirect: '0' } });
  //   return response.audioMeta;
  // }

  async getPageImageUrl(bookId: string, page: number): Promise<string> {
    const response = await this.makeRequest(`/data/${bookId}/${page.toString()}/image`, { query: { redirect: '0' } });
    return response.url;
  }

  async getBookStatus(bookId: string): Promise<BookStatus> {
    const response = await this.makeRequest(`/data/${bookId}/-1/status`);
    return response;
  }

  async makeRequest(
    path: string,
    data: { method?: string, query?: {[key: string]: string }, body?: any } = {},
  ): Promise<any> {
    const [response, statusCode] = await this.makeRequestWithStatusCode(path, data);
    if (statusCode !== 200) {
      throw new Error('Invalid response from server');
    }
    return response;
  }

  async makeRequestWithStatusCode(
    path: string,
    data: { method?: string, query?: {[key: string]: string }, body?: any } = {},
  ): Promise<[any, number]> {
    let url = `${this.baseUrl}${path}`;
    const requestConfig: any = {
      method: data.method || 'GET',
      headers: {},
    };

    if (data.body) {
      requestConfig.headers['content-type'] = 'application/json';
      requestConfig.body = JSON.stringify(data.body);
    }

    if (data) {
      if (data.method) {
        requestConfig.method = data.method;
      }
      if (data.query) {
        const queryString = Object.keys(data.query)
          .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data.query![key] || '')}`)
          .join('&');
        url += `?${queryString}`;
      }
    }

    const response = await fetch(url, requestConfig);
    return [await response.json(), response.status];
  }
}
