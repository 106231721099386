import * as React from 'react';
import ReactDom from 'react-dom';
import Container from 'react-bootstrap/Container';
import 'bootswatch/dist/sketchy/bootstrap.min.css';
import { LinkContainer } from 'react-router-bootstrap';
import {
  BrowserRouter as Router, Routes, Route, useParams, useNavigate,
} from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { RiCheckboxMultipleFill } from "react-icons/ri";
import { APIClient } from './APIClient';
import Book from './pages/Book';
import CreateBook from './pages/CreateBook';
import { SocialIcon } from 'react-social-icons'


const BookRender = function BookRender(
  props: {apiClient: APIClient},
) {
  const { apiClient } = props;
  const { bookId } = useParams();

  return <Book apiClient={apiClient} bookId={bookId || 'n/a'} />;
};
const CreateBookRender = function CreateBookRender(
  props: {apiClient: APIClient},
) {
  const { apiClient } = props;
  const navigate = useNavigate();

  return <CreateBook apiClient={apiClient} navigate={navigate} />;
};

interface MainAppState {
  apiClient: APIClient;
}


class MainApp extends React.Component {
  state: MainAppState;

  constructor(props) {
    super(props);
    this.state = { apiClient: new APIClient() };
  }

  render() {
    const { apiClient } = this.state;
    return (
      <Router>
        <Navbar variant="dark" bg="primary">
          <Container fluid>
            <LinkContainer to="/">
              <Navbar.Brand>
                GenSeen
              </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="bas-c-navbar-nav">
              <Nav className="me-auto">
                <LinkContainer to="/">
                  <Nav.Link>
                    <RiCheckboxMultipleFill style={{ fontSize: '30px' }} />
                    {' '}
                    New Unseen
                  </Nav.Link>
                </LinkContainer>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div style={{ padding: '10px' }}>
          <Routes>
            <Route path="/book/:bookId" element={<BookRender apiClient={apiClient} />} />
            <Route path="/" element={<CreateBookRender apiClient={apiClient} />} />
          </Routes>
        </div>
        <br />
        <div className='foot'>
          <p>
            Powered by <span style={{ color: 'orange', fontWeight: 'bold' }}>Amazon Web Services</span>.
            If you want to learn more, contact me on: <SocialIcon style={{ width: '30px', height: '30px' }} url="https://www.linkedin.com/in/dorman/" />
           </p>
        </div>
      </Router>      
    );
  }
}

const container = document.getElementById('app');
ReactDom.render(<MainApp />, container);
