import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { NavigateFunction } from 'react-router-dom';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { APIClient } from '../APIClient';
import Jenny from '../images/writing.jpeg';

const FOUNDATIONAL_MODELS = [
  ['Claude V1', 'anthropic.claude-v1'],
  ['Claude V2', 'anthropic.claude-v2'],
];

const LANGUAGE_OPTIONS = [
  ['English', 'english'],
  ['Hebrew', 'hebrew'],
  ['Russian', 'russian'],
  ['Arabic', 'arabic'],
];

const IMAGE_STYLE_PRESETS = [
  ['Digital Art', 'digital-art'],
  ['Photographic', 'photographic'],
  ['Cinematic', 'cinematic'],
  ['3D Model', '3d-model'],
  ['Analog Film', 'analog-film'],
  ['Comic Book', 'comic-book'],
  ['Fantasy Art', 'fantasy-art'],
  ['Line Art', 'line-art'],
  ['Origami', 'origami'],
  ['Tile Texture', 'tile-texture'],
];

interface CreateBookState {
  bookId?: string;
  plot: string;
  characters: string;
  durationMinutes: string;
  childYearsOld: string;
  inspirationBook: string;
  inspirationAuthor: string;
  modelIndex: number;
  imageStylePresetIndex: number;
}

export default class CreateBook extends Component<
{ apiClient: APIClient, navigate: NavigateFunction }
> {
  apiClient: APIClient;

  state: CreateBookState;

  navigate: NavigateFunction;

  constructor(props: { apiClient: APIClient, navigate: NavigateFunction }) {
    super(props);
    this.apiClient = props.apiClient;
    this.navigate = props.navigate;
    this.state = {
      plot: '',
      characters: '',
      durationMinutes: '',
      childYearsOld: '',
      inspirationBook: '',
      inspirationAuthor: '',
      bookId: undefined,
      modelIndex: 0,
      imageStylePresetIndex: 0,
    };

    this.onValueChange = this.onValueChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onValueChange(property, event) {
    this.setState((s) => ({ ...s, [property]: event.target.value }));
  }

  async onSubmit() {
    const {
      plot,
      childYearsOld,
      modelIndex,
      imageStylePresetIndex,
    } = this.state;

    if (!plot || !plot.trim()) {
      alert('Must provide a subject');
      return;
    }
    const finalPlot = plot;
    let finalCharacters: string|null = null;
    let finalDurationMinutes: number|null = null;
    let finalChildYearsOld: number|null = null;
    let finalInspirationBook: string|null = null;
    let finalInspirationAuthor: string|null = null;

    if (childYearsOld) {
      finalChildYearsOld = parseInt(childYearsOld, 10);
      if (Number.isNaN(finalChildYearsOld)) {
        alert('Child age is an invalid number');
        return;
      }
    }

    const bookId = await this.apiClient.createBook(
      finalPlot,
      finalCharacters,
      finalDurationMinutes,
      finalChildYearsOld,
      finalInspirationBook,
      finalInspirationAuthor,
      LANGUAGE_OPTIONS[modelIndex][1],
      IMAGE_STYLE_PRESETS[imageStylePresetIndex][1],
    );

    this.navigate(`/book/${bookId}`);
  }

  render() {
    const {
      bookId,
      plot,
      childYearsOld,
      modelIndex,
      imageStylePresetIndex,
    } = this.state;

    return (
      <>
        <h1>Create a new unseen</h1>
        <Container fluid>
          <Row>
            <Col md="auto">
              <img src={Jenny} alt="Writing" width="300" />

            </Col>
            <Col>
              <InputGroup className="mb-2">
                <InputGroup.Text>Subject</InputGroup.Text>
                <FormControl value={plot} placeholder="Enter the subject for your unseen" onChange={(e) => this.onValueChange('plot', e)} />
              </InputGroup>
              <InputGroup className="mb-2">
                <InputGroup.Text>Student Age</InputGroup.Text>
                <FormControl value={childYearsOld} placeholder="How old (in years) is the target audience?" onChange={(e) => this.onValueChange('childYearsOld', e)} />
              </InputGroup>

              <InputGroup className="mb-2">
                <InputGroup.Text>Language</InputGroup.Text>
                <DropdownButton title={LANGUAGE_OPTIONS[modelIndex][0]}>
                  {
                        LANGUAGE_OPTIONS.map((foundationalModel, index) => (
                          <Dropdown.Item
                            onClick={() => this.onValueChange('modelIndex', {
                              target: { value: index },
                            })}
                          >
                            {foundationalModel[0]}
                          </Dropdown.Item>
                        ))
                      }
                </DropdownButton>
              </InputGroup>

              {/* <InputGroup className="mb-2">
                <InputGroup.Text>Image Style Preset</InputGroup.Text>
                <DropdownButton title={IMAGE_STYLE_PRESETS[imageStylePresetIndex][0]}>
                  {
                        IMAGE_STYLE_PRESETS.map((imageStylePreset, index) => (
                          <Dropdown.Item onClick={() => this.onValueChange('imageStylePresetIndex', { target: { value: index } })}>
                            {imageStylePreset[0]}
                          </Dropdown.Item>
                        ))
                      }
                </DropdownButton>
              </InputGroup> */}
              
              <Button
                variant="success"
                onClick={this.onSubmit}
                disabled={!!bookId}
                className="mt-3 btn-lg"
              >
                Create Unseen
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
