import React, { Component, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { APIClient, BookStatus } from '../APIClient';
import Loading from '../components/Loading';

import "./App.css";

const STATUS_MESSAGES = {
  initializing: "That's a great idea, let me get started",
  creating: "I'm thinking...",
  transcribing: "I'm drawing a picture...",
  ready: 'All set!',
  queued: "I'm busy helping others right now, I'll be with you in a few moments. Sit tight and get the hot chocolate ready!",
};
interface BookState {
  status?: BookStatus;
  bookId: string;
  bookData: any;
  selectedPage: number;
  imageUrl?: string;
  textIndexes: number[];
}

export default class Book extends Component<{ apiClient: APIClient, bookId: string }> {
  apiClient: APIClient;

  state: BookState;

  constructor(props: { apiClient: APIClient, bookId: string }) {
    super(props);
    this.apiClient = props.apiClient;
    this.state = {
      status: undefined,
      bookId: props.bookId,
      bookData: null,
      selectedPage: 1,
      imageUrl: undefined,
      textIndexes: [0, 0],
    };
    this.onPageCompleted = this.onPageCompleted.bind(this);
    this.incrementPage = this.incrementPage.bind(this);
    this.pollStatus = this.pollStatus.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.pollStatus();
  }

  onPageCompleted() {
    const { bookData, selectedPage } = this.state;
    console.log('The page has been completed', { selectedPage });
    if (selectedPage >= bookData.pages.length) {
      console.log('No more pages');
      return;
    }

    console.log('incrementing');

    this.setState((s) => ({ ...s, selectedPage: selectedPage + 1, textIndexes: [0, 0] }));
    this.loadPageData(selectedPage + 1);
  }

  async pollStatus() {
    const { bookId } = this.state;
    if (!bookId) {
      throw new Error('Book ID is not found');
    }
    let status;
    try {
      status = await this.apiClient.getBookStatus(bookId);
    } catch (err) {
      console.error('Error fetching status', err);
    }
    console.log({ status });
    if (status && status.status === 'ready') {
      this.showBook();
    } else if (status) {
      this.setState((s) => ({ ...s, status }), () => setTimeout(this.pollStatus, 3000));
    } else {
      setTimeout(this.pollStatus, 3000);
    }
  }

  async showBook() {
    const { bookId } = this.state;
    const bookData = await this.apiClient.getBookData(bookId);
    console.log('Loading book data');
    this.setState((s) => ({ ...s, bookData, status: { status: 'ready' } }));
    await this.loadPageData(1);
  }

  incrementPage(increment) {
    const { selectedPage } = this.state;
    this.setState((s) => ({ ...s, selectedPage: selectedPage + increment, textIndexes: [0, 0] }));
    this.loadPageData(selectedPage + increment);
  }

  async loadPageData(pageNumber) {
    const { bookId } = this.state;

    await this.setState((s) => ({
        ...s, imageUrl: undefined, textIndexes: [0, 0],
      }));


    const getPageImageUrl = async () => {
      const imageUrl = await this.apiClient.getPageImageUrl(bookId, pageNumber);
      await this.setState((s) => ({ ...s, imageUrl }));
    };

    const promises = [
      getPageImageUrl(),
    ];

    await Promise.all(promises);
  }

  render() {
    const {
      bookData, selectedPage, textIndexes, imageUrl, status,
    } = this.state;

    let drewCount = 0;
    let drewPercentage = 0;
    let pageCount = 0;
    if (status?.pages) {
      pageCount = status.pages.length;
      drewCount = status.pages.filter((p) => p.draw === 'COMPLETED').length;
      drewPercentage = (drewCount * 100) / pageCount;
    }

    if (!status || status.status !== 'ready') {
      return (
        <div>
          {status?.title ? <h1>{status?.title}</h1> : undefined}
          <Loading />
          <h3>
            {STATUS_MESSAGES[!status ? 'initializing' : status.status]}
          </h3>
          {
            status && status.pages ? (
              <Container>
                <Row>
                  <Col>
                    {`Illustrated ${drewCount} of ${status.pages.length} images`}

                    {
                      drewPercentage < 100
                        ? (
                          <ProgressBar
                            animated
                            now={drewPercentage}
                            variant="info"
                            className="mb-2"
                          />
                        )
                        : (
                          <ProgressBar
                            now={100}
                            variant="info"
                            className="mb-2"
                          />
                        )
                    }
                  </Col>
                </Row>
              </Container>
            ) : undefined
          }
          <h5>This could take a while, go grab a snack and come back in a few minutes</h5>
        </div>
      );
    }

    if (!bookData) {
      return <Loading />;
    }
    let start = 0;
    let end = 0;
    if (textIndexes.length === 2) {
      [start, end] = textIndexes;
    }
    console.log({ status });
    
    const { text } = bookData.pages[selectedPage - 1];


    const parseStoryAndQuestions = (text) => {
      const paragraphs = text.split('\n\n');
      const story = paragraphs.slice(0, paragraphs.findIndex(paragraph => paragraph.startsWith("<example-questions>"))).join('\n\n');
    
      let questions = [];
      let currentQuestion = null;
    
      paragraphs.slice(1).forEach((paragraph) => {
        if (paragraph.startsWith("Question ")) {
          if (currentQuestion) {
            questions.push(currentQuestion);
          }
    
          const lines = paragraph.split('\n');
          const questionText = lines[0].replace(/Question \d+: /, '');
          currentQuestion = { text: questionText, options: [] };
    
          lines.slice(1).forEach((line, optionIndex) => {
            const isCorrect = line.includes('(T)');
            const optionText = line.replace(/Answer \d+: /, '').replace(/\(T\)/, '').trim();
            currentQuestion.options.push({ id: optionIndex, text: optionText, isCorrect });
          });
        } else {
          // If it's not a question, append to the current question's text
          if (currentQuestion) {
            currentQuestion.text += `\n${paragraph}`;
          }
        }
      });
    
      // Push the last question
      if (currentQuestion) {
        questions.push(currentQuestion);
      }
    
      return { story, questions };
    };

    const App = () => {

      // Properties
      const { story, questions } = parseStoryAndQuestions(text);
      const [showResults, setShowResults] = useState(false);
      const [currentQuestion, setCurrentQuestion] = useState(0);
      const [score, setScore] = useState(0);
      const { modelId } = bookData.input;

      console.log({modelId})

      // Function to set dir attribute based on language
      const setDirAttribute = (language) => {
        switch (language.toLowerCase()) {
          case "hebrew":
          case "arabic":
            return "rtl";
        default:
          return "ltr";
        }
      };

      // Set dir attribute based on language from modelId
      const dir = setDirAttribute(modelId);

      console.log({dir})

      // Helper Functions
      /* A possible answer was clicked */
      const optionClicked = (isCorrect) => {
        // Increment the score
        if (isCorrect) {
          setScore(score + 1);
        }

        if (currentQuestion + 1 < questions.length) {
          setCurrentQuestion(currentQuestion + 1);
        } else {
          setShowResults(true);
        }
      };

      /* Resets the game back to default */
      const restartGame = () => {
        setScore(0);
        setCurrentQuestion(0);
        setShowResults(false);
      };


    return (
      <div dir={dir} className="App" style={{ width: '99%' }}>
        
        <Col>
          <h1>{bookData.title} -->Score: {score} | Question: {currentQuestion + 1} out of {questions.length} </h1>
        </Col>
        <Row>
        <Col xs={2} md={3}>
          {imageUrl ? <img src={imageUrl} alt="GenSeen generated" className="img-fluid" /> : <Loading />}
        </Col>
        <Col>
        <p>
         {story}
        </p>
        
        {showResults ? (
          /* 4. Final Results */
          <div dir="ltr" className="final-results">
            <h1>Final Results</h1>
            <h2>
              {score} out of {questions.length} correct - (
              {(score / questions.length) * 100}%)
            </h2>
            <button id="submitButton" onClick={() => restartGame()}>Restart Quiz</button>
          </div>
        ) : (
          /* 5. Question Card  */
          <div className="question-card">
            <h3 className="question-text">{questions[currentQuestion].text}</h3>
  
            {/* List of possible answers  */}
            <ul>
              {questions[currentQuestion].options.map((option) => {
                return (
                  <li
                  key={option.id}
                  onClick={() => optionClicked(option.isCorrect)}
                >
                  {option.text}
                </li>
                );
              })}
            </ul>
          </div>
        )}
        </Col>
        </Row>
      </div>
    );
  };

    return (
      <Col>
        <App />
      </Col>
    );
  }
}

